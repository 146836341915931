<template>
    <div class="row align-items-center">
        <div class="col-12 mb-2 mb-md-0 col-md-9">
            <p :class="{ 'mb-0': disableForm }">
                <strong>{{document.customName || document.name}}</strong>
            </p>
            <b-form-select
                v-if="!disableForm"
                size="sm"
                :options="typeOptions"
                v-model="type"
            />
            <div v-if="showForm" class="form-sm">
                <form-input
                    :id="`document_${document.id}`"
                    :label="$t('changeDocumentCustomNameLabel')"
                    size="sm"
                    v-model="tempCustomName"
                />

                <aspena-btn
                    v-if="!disableNameChange"
                    size="sm"
                    variant="primary"
                    class="mr-2"
                    @click.prevent="onSubmit"
                >
                    {{$t('changeDocumentCustomNameSubmit')}}
                </aspena-btn>

                <aspena-btn
                    size="sm"
                    variant="danger"
                    @click.prevent="onCancel"
                >
                    {{$t('changeDocumentCustomNameCancel')}}
                </aspena-btn>
            </div>
        </div>
        <div class="col-12 mb-2 mb-md-0 text-md-right col-md-3 align-self-center">
            <aspena-btn
                v-if="!disableNameChange"
                size="sm"
                variant="primary"
                class="mr-2"
                @click.prevent="onEdit"
                :hint="$t('buttonEditHint')"
            >
                <b-icon icon="pencil"/>
            </aspena-btn>

            <aspena-btn
                size="sm"
                variant="danger"
                @click.prevent="onRemove"
                :hint="$t('buttonRemoveHint')"
            >
                <b-icon icon="trash"/>
            </aspena-btn>
        </div>
    </div>
</template>

<script>
import { Document } from '../../../DTO/Document.dto'
export default {
    name: 'DocumentsListItem',
    props: {
        document: {
            type: Object,
            required: true
        },
        typeOptions: {
            type: Array,
            required: false,
            default () {
                return []
            }
        },
        disableForm: {
            type: Boolean,
            required: false,
            default: false
        },
        disableNameChange: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    data () {
        return {
            tempCustomName: null,
            showForm: false
        }
    },
    computed: {
        type: {
            get () {
                return this.document.type
            },
            set (type) {
                this.$emit('change-type', new Document({ ...this.document, type }))
            }
        },
        customName: {
            get () {
                return this.document.customName || this.document.name
            },
            set (customName) {
                this.$emit('change-custom-name', new Document({ ...this.document, customName }))
            }
        }
    },
    methods: {
        onRemove () {
            this.$emit('remove', this.document)
        },
        onEdit () {
            this.showForm = true
            this.tempCustomName = `${this.customName}`
        },
        onSubmit () {
            this.showForm = false
            this.customName = (this.tempCustomName && `${this.tempCustomName}`.trim()) ? `${this.tempCustomName}` : null
        },
        onCancel () {
            this.showForm = false
        }
    }
}
</script>
