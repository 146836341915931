<template>
    <div class="__uploader-box">
        <file-pond
            :name="uploaderName"
            ref="filePondReference"
            :label-idle="uploaderLabel"
            :allow-multiple="multi"
            :accepted-file-types="mimeTypes"
            :server="url"
            :allowDrop="true"
            :allowRemove="false"
            :labelInvalidField="$t('uploaderBox.labelInvalidField')"
            :labelFileWaitingForSize="$t('uploaderBox.labelFileWaitingForSize')"
            :labelFileSizeNotAvailable="$t('uploaderBox.labelFileSizeNotAvailable')"
            :labelFileLoading="$t('uploaderBox.labelFileLoading')"
            :labelFileLoadError="$t('uploaderBox.labelFileLoadError')"
            :labelFileProcessing="$t('uploaderBox.labelFileProcessing')"
            :labelFileProcessingComplete="$t('uploaderBox.labelFileProcessingComplete')"
            :labelFileProcessingAborted="$t('uploaderBox.labelFileProcessingAborted')"
            :labelFileProcessingError="$t('uploaderBox.labelFileProcessingError')"
            :labelFileProcessingRevertError="$t('uploaderBox.labelFileProcessingRevertError')"
            :labelFileRemoveError="$t('uploaderBox.labelFileRemoveError')"
            :labelTapToCancel="$t('uploaderBox.labelTapToCancel')"
            :labelTapToRetry="$t('uploaderBox.labelTapToRetry')"
            :labelTapToUndo="$t('uploaderBox.labelTapToUndo')"
            :labelButtonRemoveItem="$t('uploaderBox.labelButtonRemoveItem')"
            :labelButtonAbortItemLoad="$t('uploaderBox.labelButtonAbortItemLoad')"
            :labelButtonRetryItemLoad="$t('uploaderBox.labelButtonRetryItemLoad')"
            :labelButtonAbortItemProcessing="$t('uploaderBox.labelButtonAbortItemProcessing')"
            :labelButtonUndoItemProcessing="$t('uploaderBox.labelButtonUndoItemProcessing')"
            :labelButtonRetryItemProcessing="$t('uploaderBox.labelButtonRetryItemProcessing')"
            :labelButtonProcessItem="$t('uploaderBox.labelButtonProcessItem')"
            :labelFileTypeNotAllowed="$t('uploaderBox.labelFileTypeNotAllowed')"
            :fileValidateTypeLabelExpectedTypes="`${$t('uploaderBox.fileValidateTypeLabelExpectedTypes')} {allTypes}`"
            :labelMaxFileSizeExceeded="$t('uploaderBox.labelMaxFileSizeExceeded')"
            :labelMaxFileSize="`${$t('uploaderBox.labelMaxFileSize')} {filesize}`"
            :labelMaxTotalFileSizeExceeded="$t('uploaderBox.labelMaxTotalFileSizeExceeded')"
            :labelMaxTotalFileSize="`${$t('uploaderBox.labelMaxTotalFileSize')} {filesize}`"
            maxFileSize="30MB"
            instantUpload="true"
            @processfile="onUploaderProcessFile"
            @removefile="onUploaderRemoveFile"
        />
    </div>
</template>

<script>
import 'filepond/dist/filepond.min.css'
import vueFilePondFactory from 'vue-filepond'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'

const FilePond = vueFilePondFactory(FilePondPluginFileValidateType, FilePondPluginFileValidateSize)

export default {
    name: 'UploaderBox',
    components: {
        FilePond
    },
    props: {
        uploaderName: {
            type: String,
            required: false,
            default: 'uploader'
        },
        label: {
            type: String,
            required: false
        },
        url: {
            type: String,
            required: true
        },
        multi: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data () {
        return {}
    },
    computed: {
        mimeTypes () {
            return [
                'image/jpeg',
                'image/png',
                'image/tiff',
                'application/pdf',
                'application/msword',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                'application/vnd.oasis.opendocument.text',
                'application/vnd.oasis.opendocument.spreadsheet',
                'application/vnd.ms-excel',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            ].join(', ')
        },
        uploaderLabel () {
            if (this.label) {
                return this.label
            }
            return `<strong>${this.$t('uploaderBox.labelText')}<span class="filepond--label-action">${this.$t('uploaderBox.labelTextLink')}</span></strong>`
        }
    },
    methods: {
        onUploaderProcessFile (error, data) {
            if (error) {
                console.error(error)
            } else {
                this.$refs.filePondReference.removeFile(data.id)
                this.$emit('uploaded', data)
            }
        },
        onUploaderRemoveFile (error, data) {
            if (error) {
                console.error(error)
            } else {
                this.$emit('removed', data)
            }
        }
    }
}
</script>
