<template>
    <div class="form-sm">
        <div v-show="documentsList.length === 0" class="alert alert-primary text-center mb-4">
            {{$t('documents.emptyDocuments')}}
        </div>
        <slot name="errors"></slot>

        <div v-if="!showReferences && isMissingTypes" class="alert alert-danger">
            {{$t('documents.missingTypes')}}
        </div>

        <div class="mb-4">
            <loader-overlay
                :loading="loading"
            >
                <div class="list-group mb-3 references-list" v-show="documentsList.length !== 0">
                    <div class="list-group-item">
                        <div class="row">
                            <div class="col-12 mb-2 col-md-9">
                                <strong>{{$t('documents.fileName')}}</strong>
                            </div>
                        </div>
                    </div>
                    <div class="list-group-item" v-for="document in documentsList" :key="document.id">
                        <documents-list-item
                            :disable-form="disableForm"
                            :disable-name-change="disableNameChange"
                            :document="document"
                            :type-options="documentTypesOptions"
                            @change-type="onChangeType"
                            @change-custom-name="onChangeCustomName"
                            @remove="onRemove"
                        />
                    </div>
                </div>
            </loader-overlay>
        </div>
    </div>
</template>

<script>
import LoaderOverlay from '../../../Components/LoaderOverlay.vue'
import DocumentsListItem from './DocumentsListItem.vue'

export default {
    name: 'DocumentsList',
    components: {
        DocumentsListItem,
        LoaderOverlay
    },
    props: {
        value: {
            type: Array,
            required: true
        },
        disableForm: {
            type: Boolean,
            required: false,
            default: false
        },
        disableNameChange: {
            type: Boolean,
            required: false,
            default: true
        },
        showReferences: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    data () {
        return {
            loading: false
        }
    },
    computed: {
        documentsList () {
            return this.documents.filter(item => (this.showReferences) ? item.tempId : !item.tempId)
        },
        isMissingTypes () {
            for (const item of this.documentsList) {
                if (!item.type) {
                    return true
                }
            }
            return false
        },
        documents: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            }
        },
        documentTypesOptions () {
            const options = JSON.parse(JSON.stringify(this.$store.state.enums.documentTypesOptions || []))
            options.sort((a, b) => a.text.localeCompare(b.text, navigator.languages[0] || navigator.language, { numeric: true, ignorePunctuation: true }))
            return [{ value: null, text: this.$t('documents.typesPrompt') }, ...options].filter(item => item.value !== 'referenceDocuments')
        }
    },
    methods: {
        onRemove (document) {
            this.loading = true
            this.$api.uploads.delete(document.id)
                .then(() => {
                    this.documents = this.documents.filter(item => item.id !== document.id)
                }).catch((error) => {
                    console.error(error)
                    this.$notify.error(this.$t('documents.removalHasFailed'))
                }).finally(() => {
                    this.$nextTick(() => { this.loading = false })
                })
        },
        onChangeCustomName (document) {
            this.documents = this.documents.map(item => (item.id === document.id) ? document : item)
        },
        onChangeType (document) {
            this.documents = this.documents.map(item => (item.id === document.id) ? document : item)
            /*
            this.$api.uploads.updateUploadType(document.id, document.type)
                .then((response) => {
                    this.documents = this.documents.map(item => (item.id === document.id) ? new Document(response.data) : item)
                }).catch((error) => {
                    console.error(error)
                    this.$notify.error(this.$t('documents.updateTypeHasFailed'))
                }).finally(() => {
                    this.$nextTick(() => { this.loading = false })
                })
            */
        }
    }
}
</script>
